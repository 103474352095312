import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import PropTypes from 'prop-types';
import RecipeEditorialList from '../components/recipe-editorial-list';

import { rhythm } from '../utils/typography';
import styled from 'styled-components';

const RecipeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    list-style-type: none;
  }

  li:before {
    content: none;
  }
`;

function RecipeListForChef({ chef }) {
  return (
    <StaticQuery
      query={recipeListForChefQuery}
      render={(data) => {
        const recipes = data.allMarkdownRemark.edges;
        const filteredRecipes = recipes.filter(({ node }) => {
          return (
            node &&
            node.frontmatter &&
            node.frontmatter.sources &&
            node.frontmatter.sources.find((source) => {
              return source.chef === chef;
            })
          );
        });
        return (
          <div>
            <h2>Recipes</h2>
            <p>
              The following are recipes that I've tried or adapted by {chef}:
            </p>
            <hr />
            <RecipeList>
              {filteredRecipes.map(({ node }, idx) => {
                return (
                  <li key={idx}>
                    <RecipeEditorialList
                      recipe={node}
                      index={idx}
                    ></RecipeEditorialList>
                  </li>
                );
              })}
            </RecipeList>
            {!!filteredRecipes.length && (
              <hr
                style={{
                  marginBottom: rhythm(1),
                }}
              />
            )}
          </div>
        );
      }}
    />
  );
}

RecipeListForChef.propTypes = {
  chef: PropTypes.string,
};

const recipeListForChefQuery = graphql`
  query RecipeListForChefQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { path: { eq: "recipe" } }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            sources {
              chef
            }
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 767) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecipeListForChef;
