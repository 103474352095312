import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledImg = styled(Img)`
  display: block !important;
`;

const Product = styled.div`
  border: 1px solid gray;
  padding: 10px;
  max-width: 600px;
  min-width: 300px;
  min-height: 180px;
  position: relative;
  display: block;
  margin-bottom: 15px;

  a {
    @media only screen and (min-width: 600px) {
      min-width: 300px;
    }
  }
  div.gatsby-image-wrapper {
    margin: 20px;
  }

  div.info {
    @media only screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      top: 30px;
      right: 20px;
      max-width: 270px;
    }
  }

  div.infoDetails {
    display: flex;
    flex-direction: column;
    justify-items: space-around;
    height: 100%;
    align-content: center;
    margin-top: 10px;
    a {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
    }
  }

  div.buyButton {
    margin-top: 30px;
    background-color: #ee5a5a;
    color: #fff;
    font-weight: 700;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }
`;

function ProductCard({ name, asin, image }) {
  return (
    <StaticQuery
      query={productCardQuery}
      render={(data) => {
        const { amazonTag } = data.site.siteMetadata;
        const link = 'https://www.amazon.com/dp/' + asin + '?tag=' + amazonTag;
        return (
          <Product>
            <OutboundLink href={link} target="_blank">
              <Img fluid={image} />
              <div className="info">
                <div className="infoDetails">
                  <strong>{name}</strong>
                  <div className="buyButton">Buy on Amazon</div>
                </div>
              </div>
            </OutboundLink>
          </Product>
        );
      }}
    />
  );
}

ProductCard.propTypes = {
  name: PropTypes.string,
  asin: PropTypes.string,
  image: PropTypes.object,
};

const productCardQuery = graphql`
  query ProductCardQuery {
    site {
      siteMetadata {
        amazonTag
      }
    }
  }
`;

export default ProductCard;
