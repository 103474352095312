import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { rhythm } from '../../utils/typography';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Recipe = styled(Link)`
  background: #fff;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 800px) {
    flex-direction: row;
    padding: 10px;
    min-height: 175px;
  }

  .card-image {
    width: 100%;
    height: auto;
    @media only screen and (min-width: 800px) {
      width: 65%;
      height: auto;
      flex-shrink: 0;
    }
  }

  .card-body {
    margin-bottom: 30px;
    @media only screen and (min-width: 800px) {
      margin-bottom: 0;
      padding: 6rem 3rem;
      width: 35%;
    }
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.4;
    }
  }

  &.direction-r {
    @media only screen and (min-width: 800px) {
      flex-direction: row-reverse;
    }
  }
`;

const RecipeEditorialList = (props) => {
  const { recipe, index } = props;
  const title = recipe.frontmatter.title || recipe.fields.slug;

  return recipe && recipe.fields ? (
    <Recipe
      className={'direction-' + (index % 2 ? 'r' : 'l')}
      key={recipe.fields.slug}
      to={'/' + recipe.fields.path + recipe.fields.slug}
    >
      {recipe.frontmatter.featuredImage && (
        <Img
          className="card-image"
          style={{
            marginBottom: rhythm(1 / 2),
          }}
          fluid={recipe.frontmatter.featuredImage.childImageSharp.sizes}
        />
      )}
      <div className="card-body">
        <h3
          style={{
            marginBottom: rhythm(1 / 2),
            marginLeft: rhythm(1 / 4),
            marginRight: rhythm(1 / 4),
          }}
        >
          {title}
        </h3>
        <p
          style={{
            marginLeft: rhythm(1 / 4),
            marginRight: rhythm(1 / 4),
          }}
          dangerouslySetInnerHTML={{ __html: recipe.excerpt }}
        />
        <p
          style={{
            marginLeft: rhythm(1 / 4),
            marginRight: rhythm(1 / 4),
            marginBottom: rhythm(1 / 4),
          }}
        >
          {recipe.frontmatter.date}
        </p>
      </div>
    </Recipe>
  ) : (
    ''
  );
};

RecipeEditorialList.propTypes = {
  index: PropTypes.number,
  recipe: PropTypes.object.isRequired,
};

export default RecipeEditorialList;
