import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import ProductCard from "../components/product-card"
import RecipeListForChef from "../components/recipe-list-for-chef"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Breadcrumb = styled.div`
font-weight: 300
`

const BookList = styled.ul`
list-style: none;
`

const ChefHeader = styled.div`
`

class ChefPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const seoTitle=`${post.frontmatter.title} Recipes`;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={seoTitle}
          description={post.excerpt}
          images={post.frontmatter.image && [post.frontmatter.image.childImageSharp.sizes.src]}
          />
        <ChefHeader>
        <h1>{seoTitle}</h1>
        {post.frontmatter.image &&
          <Img
            property="image"
            imgClassName="chefImage"
            fluid={post.frontmatter.image.childImageSharp.sizes} />
        }
        </ChefHeader>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.books && (
                <BookList>
                  {post.frontmatter.books.map((book, idx) => {
                    return (
                      <li key={idx}>

          <ProductCard
            name={book.name}
            asin={book.asin}
            price={book.price}
            image={book.image.childImageSharp.sizes}
          />
                      </li>
                    );
                  })}
                </BookList>
            )}
        <RecipeListForChef
          chef={post.frontmatter.title}
        />
        <Bio />
        <ul
          style={{
            marginTop: `10px`,
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={'/' + previous.fields.path + previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={'/' + next.fields.path + next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default ChefPostTemplate

export const pageQuery = graphql`
  query ChefPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        image {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        books {
          name
          asin
          price
          image {
            childImageSharp {
              sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`
